<template>
  <div id="app"></div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    this.getEnrolledCoursesForTeacher()
  },
  methods: {
    async getEnrolledCoursesForTeacher() {
      const response = await fetch("https://fhict.instructure.com/api/v1/courses/?enrollmenttype=teacher&enrollment_state=active&include[]=term", {
        // mode: "no-cors",
        method: "GET",
        headers: {
          Authorization: "Bearer 2464~sGDU7UNUigPHZUUCRRPgnHihlFL9mfOo6yWDWmiMewHL6oyIhsMZz6Ip8KRvH2Q6",
        },
      })
      const courses = await response.json()
      console.log(courses)
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
